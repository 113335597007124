import React, { useEffect, useRef } from 'react';

interface SectionProps {
  id: string;
  className?: string;
  children: React.ReactNode;
}

export const Section: React.FC<SectionProps> = ({ id, className = '', children }) => {
  const sectionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const section = sectionRef.current;
    if (!section) return;

    const observer = new IntersectionObserver(
      entries => {
        // Ensure we have entries and access first entry safely
        const entry = entries[0];
        if (!entry) return;

        if (entry.isIntersecting) {
          section.classList.add('opacity-100', 'translate-y-0');
          section.classList.remove('opacity-0', 'translate-y-8');
        }
      },
      {
        threshold: 0.1,
        rootMargin: '0px'
      }
    );

    observer.observe(section);

    // Cleanup observer on unmount
    return () => {
      observer.unobserve(section);
    };
  }, []);

  return (
    <section
      id={id}
      ref={sectionRef}
      className={`
        min-h-screen
        w-full
        flex
        items-center
        justify-center
        transition-all
        duration-700
        opacity-0
        translate-y-8
        ${className}
      `}
    >
      {children}
    </section>
  );
};