import React from 'react';
import { Section } from './Section';

const Word: React.FC<{ 
  word: string; 
  isItalic?: boolean; 
  isSerif?: boolean; 
  className?: string;
}> = ({ word, isItalic, isSerif, className = '' }) => (
  <span
    className={`
      inline-block
      text-white
      hover:text-orange-500
      transition-all
      duration-300
      ${isItalic ? 'italic' : ''}
      ${isSerif ? 'font-serif' : ''}
      ${className}
      magic-hover
    `}
  >
    {word}
  </span>
);

export const HeroSection: React.FC = () => {
  return (
    <Section id="home" className="min-h-screen">
      <div className="absolute inset-0 flex items-center justify-center">
        <h1 className="text-8xl font-light tracking-tight text-center">
          <div className="space-y-4">
            <div className="flex items-baseline justify-center gap-3">
              <Word word="I'm" isItalic isSerif className="text-7xl" />
              <Word word="a" isItalic isSerif className="text-7xl" />
            </div>
            <div>
              <Word word="FULL-STACK" className="tracking-wider" />
            </div>
            <div>
              <Word word="AI" className="tracking-wider" />
            </div>
            <div className="flex justify-center items-baseline gap-3">
              <Word word="&" isSerif className="text-7xl" />
            </div>
            <div>
              <Word word="SOFTWARE" className="tracking-wider" />
            </div>
            <div>
              <Word word="ENGINEER" className="tracking-wider" />
            </div>
          </div>
        </h1>
      </div>
    </Section>
  );
};