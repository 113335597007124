import React from 'react';
import { useSmoothScroll } from '../../hooks/useSmoothScroll';

const navLinks = [
  { href: 'home', label: 'Home' },
  { href: 'work', label: 'Work' },
  { href: 'services', label: 'Services' },
  { href: 'about', label: 'About' },
  { href: 'contact', label: 'Contact' }
];

export const Navigation: React.FC = () => {
  const { scrollToSection } = useSmoothScroll();

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>, sectionId: string) => {
    e.preventDefault();
    scrollToSection(sectionId);
  };

  return (
    <nav className="fixed top-0 right-0 p-8 z-50">
      <ul className="flex gap-12">
        {navLinks.map((link) => (
          <li key={link.href}>
            <a
              href={`#${link.href}`}
              onClick={(e) => handleClick(e, link.href)}
              className="text-white hover:text-orange-500 transition-all duration-300 text-lg magic-hover"
            >
              {link.label}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};