import React, { useEffect, useRef } from 'react';

interface Particle3D {
  x: number;
  y: number;
  z: number;
  speed: number;
  size: number;
  opacity: number;
  type: 'dot' | 'cross' | 'line';
}

export const ParticleBackground: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const animationFrameRef = useRef<number | null>(null);
  const particlesRef = useRef<Particle3D[]>([]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const handleResize = () => {
      const { innerWidth, innerHeight } = window;
      canvas.width = innerWidth;
      canvas.height = innerHeight;
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    // Initialize particles if not already done
    if (particlesRef.current.length === 0) {
      const particleCount = 250;
      const maxDepth = 1000;

      for (let i = 0; i < particleCount; i++) {
        particlesRef.current.push({
          x: Math.random() * canvas.width - canvas.width / 2,
          y: Math.random() * canvas.height - canvas.height / 2,
          z: Math.random() * maxDepth,
          speed: 0.3 + Math.random() * 0.4,
          size: 1 + Math.random() * 2,
          opacity: Math.random(),
          type: Math.random() > 0.7 ? 'cross' : Math.random() > 0.5 ? 'line' : 'dot'
        });
      }
    }

    const drawParticle = (x: number, y: number, size: number, opacity: number, type: 'dot' | 'cross' | 'line') => {
      if (!ctx) return;

      ctx.strokeStyle = `rgba(255, 98, 42, ${opacity * 0.8})`;
      ctx.fillStyle = `rgba(255, 98, 42, ${opacity * 0.8})`;
      ctx.lineWidth = 1;

      switch (type) {
        case 'cross':
          const halfSize = size * 2;
          ctx.beginPath();
          ctx.moveTo(x - halfSize, y - halfSize);
          ctx.lineTo(x + halfSize, y + halfSize);
          ctx.moveTo(x + halfSize, y - halfSize);
          ctx.lineTo(x - halfSize, y + halfSize);
          ctx.stroke();
          break;
        case 'line':
          ctx.beginPath();
          ctx.moveTo(x - size * 3, y);
          ctx.lineTo(x + size * 3, y);
          ctx.stroke();
          break;
        default: // dot
          ctx.beginPath();
          ctx.arc(x, y, size, 0, Math.PI * 2);
          ctx.fill();
      }
    };

    const animate = () => {
      if (!ctx || !canvas) return;

      // Semi-transparent black background for trail effect
      ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      const perspective = 800;
      const maxDepth = 1000;

      particlesRef.current.forEach(particle => {
        // Move particle
        particle.z -= particle.speed;

        // Reset particle if it's too close
        if (particle.z < 1) {
          particle.x = Math.random() * canvas.width - canvas.width / 2;
          particle.y = Math.random() * canvas.height - canvas.height / 2;
          particle.z = maxDepth;
        }

        // Project 3D coordinates into 2D space
        const scale = perspective / (perspective + particle.z);
        const x2d = canvas.width / 2 + particle.x * scale;
        const y2d = canvas.height / 2 + particle.y * scale;

        // Adjust size and opacity based on depth
        const adjustedSize = particle.size * scale;
        const adjustedOpacity = particle.opacity * (1 - particle.z / maxDepth);

        // Draw the particle
        drawParticle(x2d, y2d, adjustedSize, adjustedOpacity, particle.type);
      });

      animationFrameRef.current = requestAnimationFrame(animate);
    };

    // Start animation
    animate();

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      className="fixed inset-0 bg-black"
    />
  );
};