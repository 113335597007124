import React from 'react';
import { MainLayout } from './components/layout/MainLayout';
import { Navigation } from './components/layout/Navigation';
import { ParticleBackground } from './components/effects/ParticleBackground';
import { CustomCursor } from './components/effects/CustomCursor';
import { HeroSection } from './components/sections/HeroSection';
import { WorkSection } from './components/sections/WorkSection';
import { ServicesSection } from './components/sections/ServicesSection';
import { AboutSection } from './components/sections/AboutSection';
import { ContactSection } from './components/sections/ContactSection';

const App: React.FC = () => {
  return (
    <MainLayout>
      <div className="fixed inset-0 z-0">
        <ParticleBackground />
      </div>

      <div className="relative z-10 h-full scroll-container">
        <Navigation />
        
        <div className="relative">
          <div id="home" className="snap-section">
            <HeroSection />
          </div>
          
          <div id="work" className="snap-section">
            <WorkSection />
          </div>
          <div id="services" className="snap-section">
            <ServicesSection />
          </div>
          <div id="about" className="snap-section">
            <AboutSection />
          </div>
          <div id="contact" className="snap-section">
            <ContactSection />
          </div>
        </div>
      </div>

      <CustomCursor />
    </MainLayout>
  );
};

export default App;