import React from 'react';
import { Section } from './Section';

interface SkillGroupProps {
 title: string;
 skills: string[];
}

interface WorkExperience {
 company: string;
 role: string;
 period: string;
 location: string;
 achievements: string[];
}

interface CertificationItem {
 name: string;
 authors?: string;
 type?: string;
}

interface Certification {
 category: string;
 name?: string;
 issuer?: string;
 period?: string;
 location?: string;
 specializations?: string[];
 items?: CertificationItem[];
}

const IntroSection: React.FC = () => {
 const [isVisible, setIsVisible] = React.useState(false);
 const [imageLoaded, setImageLoaded] = React.useState(false);
 
 React.useEffect(() => {
   setIsVisible(true);
 }, []);

 return (
   <div className="min-h-[50vh] flex flex-col justify-center relative mb-8 overflow-hidden">
     {/* Profile Image */}
     <div className={`absolute right-12 top-1/2 -translate-y-1/2 w-[400px] transition-all duration-1000 delay-500 transform ${
       isVisible ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0'
     }`}>
       <div className="aspect-square relative overflow-hidden rounded-3xl">
         <img
           src="/profile_pic.png"
           alt="Ivan Raykov"
           className={`
             object-cover 
             w-full 
             h-full 
             transition-opacity duration-500
             ${imageLoaded ? 'opacity-100' : 'opacity-0'}
           `}
           onLoad={() => setImageLoaded(true)}
         />
         <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/50 to-transparent pointer-events-none"></div>
       </div>
     </div>

     {/* Text Content */}
     <div className={`max-w-[55%] transition-all duration-1000 transform ${
       isVisible ? 'translate-x-0 opacity-100' : '-translate-x-full opacity-0'
     }`}>
       <h1 className="text-[7vw] font-light italic leading-none text-white/90 mb-2">
         hi there, I'm
       </h1>
       <h2 className="text-[10vw] font-light leading-none mb-4 text-white">
         Ivan Raykov
       </h2>
     </div>
   </div>
 );
};

const IntroDescription: React.FC = () => (
 <div className="max-w-7xl mb-32 grid grid-cols-2 gap-16">
   <div>
     <p className="text-xl text-white/80 leading-relaxed">
       At the intersection of finance and artificial intelligence, I've forged a unique path that combines deep financial markets expertise with cutting-edge AI engineering. My foundation in financial derivatives and statistical analysis, coupled with hands-on experience in blockchain technologies and algorithmic trading, enables me to bridge the gap between complex financial challenges and innovative technological solutions.
     </p>
     <p className="text-xl text-white/80 leading-relaxed mt-8">
       My journey began in traditional finance, where I honed my skills analyzing investment portfolios for institutional clients managing over $1.4 trillion in assets. This experience provided me with invaluable insights into the intricate workings of financial markets and the critical importance of data-driven decision making. Today, I leverage this background to develop sophisticated AI systems that transform raw financial data into actionable intelligence.
     </p>
   </div>
   <div>
     <p className="text-xl text-white/80 leading-relaxed">
       In my current role as a Data & AI Engineer, I specialize in architecting enterprise-scale data solutions and AI systems that drive innovation in the financial and cryptocurrency sectors. From developing LLM-based business automation solutions to implementing advanced trading infrastructure, I combine financial domain expertise with technical excellence to deliver systems that achieve over 95% accuracy in real-world applications. My work spans the full technical spectrum - from building distributed systems handling 1000+ concurrent requests to implementing sophisticated ML pipelines for alpha signal generation.
     </p>
     <p className="text-xl text-white/80 leading-relaxed mt-8">
       I'm particularly passionate about the democratization of financial technology, having contributed to projects that make sophisticated financial tools more accessible to businesses and individuals alike. This includes my work with cryptocurrency infrastructure and developing statistical arbitrage models that bridge traditional and digital asset markets.
     </p>
   </div>
 </div>
);

const SkillGroup: React.FC<SkillGroupProps> = ({ title, skills }) => (
 <div>
   <h4 className="text-orange-500 text-xl capitalize mb-6">
     {title}
   </h4>
   <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-12 gap-y-3">
     {skills.map((skill) => (
       <span key={skill} className="text-white/80 text-lg hover:text-white transition-colors">
         {skill}
       </span>
     ))}
   </div>
 </div>
);

const skillsData: Record<string, string[]> = {
 frameworks: [
   'React', 'TypeScript', 'Tailwind CSS', 'Shadcn/UI',
   'HuggingFace', 'LangChain', 'React Query', 'React Hook Form',
   'transformers', 'SKLearn', 'pandas', 'numpy'
 ],
 
 tools: [
   'Docker', 'Git', 'AWS (ECS, Lambda, Athena, S3)', 'Azure SQL',
   'Google Cloud', 'Vite', 'npm/yarn', 'PostgreSQL',
   'MySQL', 'MongoDB', 'S3', 'SQLAlchemy'
 ],
 
 methodologies: [
   'CI/CD', 'Agile Development', 'Test-Driven Development',
   'Microservices Architecture', 'Data Lake Architecture', 'RESTful APIs',
   'Statistical Analysis', 'Feature Engineering', 'Grid/Flex Layouts',
   'Responsive Design'
 ],
 
 backend: [
   'Python', 'SQL', 'Node.js', 'AWS Services',
   'Database Design', 'API Development', 'Data Pipeline Development',
   'System Architecture', 'Performance Optimization'
 ],

 AI_ML: [
   'LLM Fine-tuning', 'RAG (Retrieval-Augmented Generation)',
   'Prompt Engineering', 'LLaMA', 'Anthropic Claude', 'OpenAI',
   'Hugging Face Transformers', 'Machine Learning Pipelines',
   'Statistical Modeling', 'Sentiment Analysis', 'NLP Techniques'
 ]
};

const workExperience: WorkExperience[] = [
 {
   company: 'Stealth Startup',
   role: 'Custom LLM Agents for Business Automation',
   period: '08/2024 - Present',
   location: 'Las Vegas, United States',
   achievements: [
     'Architecte and implement enterprise-grade AI system enabling small businesses to automate and improve repetative tasks',
   ]
 },
 {
   company: 'Apollo Management LLC',
   role: 'Enterprise Architect',
   period: '07/2023 - 08/2024',
   location: 'Las Vegas, United States',
   achievements: [
     'Designed and implemented comprehensive Data Lake architecture from ground up',
     'Led strategic migration from Azure SQL to AWS PostgreSQL infrastructure',
     'Implemented Slowly Changing Dimension (SCD) Type 2 methodology across database',
     'Orchestrated multi-source data integration including Google Sheets APIs',
     'Established Tableau reporting infrastructure and managed access controls',
     'Migrated data collection processes to AWS ECS for enhanced scalability'
   ]
 },
 {
   company: 'Nexo',
   role: 'Data Scientist / Data Engineer',
   period: '03/2022 - 05/2023',
   location: 'Sofia, Bulgaria',
   achievements: [
      'Global leader in digital assets lending with over $4B in AUM serving 5M+ users worldwide',
      'Designed and implemented end-to-end ML pipelines for alpha signal generation',
      'Created and maintained PnL calculations for 50+ trading strategies across Options, Spot, Futures, and Perpetual Futures',
      'Engineered advanced feature pipeline combining blockchain and FRED economic data for trading signals',
      'Enhanced Alpha generation through advanced sentiment analysis and NLP techniques',
      'Maintained data integrity across 30+ trading datasets for Market Making, HFT, MFT, and Options teams',
      'Implemented Leading Pairs valuation for USD-denominated PnL reporting',
      'Developed statistical arbitrage models using multivariate analysis and time series modeling',
      'Created automated backtesting framework for strategy validation with real-time dashboard',
      'Applied statistical arbitrage techniques across crypto and traditional markets',
      'Conducted sophisticated blockchain data analysis through advanced scraping techniques',
      'Engineered features using statistical methods for signal generation'
   ]
 },
 {
  company: 'Stealth Startup',
  role: 'Data Scientist / Data Engineer',
  period: '01/2021 - 12/2021',
  location: 'Remote, USA',
  achievements: [
    'Developed and maintained Python data pipelines processing 10TB+ of financial market data daily',
    'Implemented statistical arbitrage models for crypto markets using pandas and numpy',
    'Created automated backtesting framework for strategy validation using PostgreSQL',
    'Built real-time dashboard for monitoring trading signals with React and TypeScript',
    'Integrated multiple data sources including FRED economic data and crypto market APIs',
    'Optimized data processing workflows reducing computation time by 40%',
    'Collaborated with trading desk to implement risk management features'
  ]
 },
 {
   company: 'Bulgarian Stock Exchange',
   role: 'Manager, SME Growth Market "beam"',
   period: '02/2020 - 08/2020',
   location: 'Sofia, Bulgaria',
   achievements: [
     'A specialized BSE market segment enabling Bulgarian SMEs to access public financing through streamlined listing requirements',
     'Directed strategic development of new BEAM market for Bulgarian SME financing',
     'Achieved 20% business growth through optimized pricing strategies',
     'Advised potential companies on onboarding processes',
     'Oversaw market operations aligned with primary BSE market rules'
   ]
 },
 {
  company: 'Financial Market Services',
  role: 'General Manager',
  period: '02/2018 - 08/2020',
  location: 'Sofia, Bulgaria',
  achievements: [
    'A Bulgarian Stock Exchange subsidiary operating critical market infrastructure platforms for regulated information disclosure and financial market data analytics.',
    'Led BSE subsidiary operating integrated digital disclosure system for public companies',
    'Oversaw development of analytical software for BSE-listed firms',
    'Managed client relationships with public companies and government agencies',
    'Developed and trained new team members',
    'Represented organization at international conferences',
    'Maintained data integrity in client databases and CRM systems achievements',
    'Automated import of balance sheets and accounting information'
    
  ]
  },
 {
   company: 'NEPC, LLC',
   role: 'Senior Performance Analyst',
   period: '07/2014 - 07/2017',
   location: 'Las Vegas, United States',
   achievements: [
     'Analyzed investment portfolios for public and Taft-Hartley pension funds',
     'Managed comprehensive client correspondence and relationship maintenance',
     'Executed complex ad hoc analysis projects for partners and consultants',
     'Led performance reporting and analytics initiatives for institutional clients'
   ]
 }
];

const certifications: Certification[] = [
 {
   category: 'Education',
   name: 'BSBA, Finance',
   issuer: 'University of Nevada Las Vegas',
   period: '2008 - 2012',
   location: 'Las Vegas, NV',
   specializations: [
     'Excellence in Financial Derivatives coursework',
     'Advanced Statistical Analysis focus'
   ]
 }
];

export const AboutSection: React.FC = () => {
 return (
   <Section id="about" className="bg-black px-8">
     <div className="max-w-7xl mx-auto w-full py-16">
       <IntroSection />
       <IntroDescription />
       
       {/* Skills Section */}
       <div className="mb-16">
         <h3 className="text-2xl text-white/60 mb-12">
           <span className="text-white/40 mr-4">01</span>
           Software skills
         </h3>
         <div className="space-y-16">
           {Object.entries(skillsData).map(([category, skills]) => (
             <SkillGroup 
               key={category} 
               title={category.replace('_', ' ')} 
               skills={skills} 
             />
           ))}
         </div>
       </div>

       {/* Divider Line */}
       <div className="w-full h-[2px] bg-gradient-to-r from-white/5 via-white/10 to-transparent mb-16"></div>

       {/* Work Experience Section */}
       <div className="mb-16">
         <h3 className="text-2xl text-white/60 mb-8">
           <span className="text-white/40 mr-4">02</span>
           Work experience
         </h3>
         <div className="space-y-16">
           {workExperience.map((job: WorkExperience) => (
             <div key={`${job.company}-${job.period}`}>
               <div className="grid grid-cols-12 gap-4 mb-4">
                 <div className="col-span-4">
                   <span className="text-white text-xl">{job.company}</span>
                 </div>
                 <div className="col-span-5">
                   <span className="text-white/80 text-lg">{job.role}</span>
                 </div>
                 <div className="col-span-3 text-right">
                   <span className="text-white/60">{job.period}</span>
                   <br />
                   <span className="text-white/40 text-sm">{job.location}</span>
                 </div>
               </div>
               <ul className="list-disc pl-5 space-y-2 text-white/70">
                 {job.achievements.map((achievement: string, index: number) => (
                   <li key={index}>{achievement}</li>
                 ))}
               </ul>
             </div>
           ))}
         </div>
       </div>

       {/* Divider Line */}
       <div className="w-full h-[2px] bg-gradient-to-r from-white/5 via-white/10 to-transparent mb-16"></div>

       {/* Education Section */}
       <div>
         <h3 className="text-2xl text-white/60 mb-8">
           <span className="text-white/40 mr-4">03</span>
           Education & Publications
         </h3>
         <div className="space-y-16">
           {certifications.map((cert: Certification, idx: number) => (
             <div key={idx}>
               <h4 className="text-orange-500 text-xl mb-6">{cert.category}</h4>
               
               {cert.category === 'Education' ? (
                 <div>
                   <div className="grid grid-cols-12 gap-4 mb-4">
                     <div className="col-span-4">
                       <span className="text-white text-lg">{cert.name}</span>
                     </div>
                     <div className="col-span-4">
                       <span className="text-white/80">{cert.issuer}</span>
                     </div>
                     <div className="col-span-4 text-right">
                       <span className="text-white/60">{cert.period}</span>
                       <br />
                       <span className="text-white/40 text-sm">{cert.location}</span>
                     </div>
                   </div>
                   <ul className="list-disc pl-5 space-y-2 text-white/70">
                     {cert.specializations?.map((spec: string, index: number) => (
                       <li key={index}>{spec}</li>
                     ))}
                   </ul>
                 </div>
               ) : (
                 <div className="grid grid-cols-2 gap-8">
                   {cert.items?.map((item: CertificationItem, index: number) => (
                     <div key={index} className="bg-white/5 p-6 rounded-lg">
                       <h5 className="text-white text-lg mb-2">{item.name}</h5>
                       <p className="text-white/60">{item.authors}</p>
                       <p className="text-white/40 text-sm mt-2">{item.type}</p>
                     </div>
                   ))}
                 </div>
               )}
             </div>
           ))}
         </div>
       </div>
     </div>
   </Section>
 );
};