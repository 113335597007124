import { useEffect } from 'react';

interface MagicMouseOptions {
  outerStyle?: "circle" | "square" | "disable";
  outerWidth?: number;
  outerHeight?: number;
  hoverEffect?: "circle-move" | "pointer-blur" | "pointer-overlay";
  hoverItemMove?: boolean;
  defaultCursor?: boolean;
}

declare global {
  interface Window {
    magicMouse: (options: MagicMouseOptions) => void;
  }
}

interface CustomCursorProps {
  options?: MagicMouseOptions;
}

export const CustomCursor: React.FC<CustomCursorProps> = () => {
  useEffect(() => {
    const scriptId = 'magic-mouse-script';
    let existingScript = document.getElementById(scriptId) as HTMLScriptElement | null;
    let timeoutId: NodeJS.Timeout;

    const cleanupCursor = () => {
      const elementsToRemove = [
        '#magicMouseCursor',
        '#magicPointer'
      ];

      elementsToRemove.forEach(selector => {
        const element = document.querySelector(selector);
        if (element) {
          element.remove();
        }
      });
    };

    const initializeCursor = () => {
      if (!existingScript) {
        existingScript = document.createElement('script');
        existingScript.id = scriptId;
        existingScript.src = "https://res.cloudinary.com/veseylab/raw/upload/v1684982764/magicmouse-2.0.0.cdn.min.js";
        existingScript.async = true;

        existingScript.onload = () => {
          timeoutId = setTimeout(() => {
            if (typeof window.magicMouse === 'function') {
              cleanupCursor();
              window.magicMouse({
                outerStyle: "disable",
                hoverEffect: "pointer-blur",
                hoverItemMove: false,
                defaultCursor: false,
                outerWidth: 30,
                outerHeight: 30
              });
            }
          }, 100);
        };

        document.body.appendChild(existingScript);
      }
    };

    initializeCursor();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      cleanupCursor();
    };
  }, []);

  return null;
};