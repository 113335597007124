import React, { useState } from 'react';
import { ArrowUpRight } from 'lucide-react';
import { MarqueeText } from '../common/MarqueeText';

export const ContactSection: React.FC = () => {
  const [interest, setInterest] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Add form submission logic here
  };

  return (
    <section id="contact" className="min-h-screen flex flex-col justify-center px-4 py-20">
      {/* Marquee Text */}
      <div className="mb-20 -mx-4">
        <MarqueeText 
          text="SAY HELLO!" 
          className="text-8xl font-light text-white"
        />
      </div>

      {/* Main Content */}
      <div className="w-full max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16">
        {/* Left Column - Text */}
        <div className="space-y-8">
          <p className="text-xl text-white/80 leading-relaxed max-w-xl">
            Ready to elevate your code base to new heights? Experience the 
            exceptional expertise of a skilled mentor who effortlessly propels 
            projects to the next level. Let's partner with your needs to 
            maximize your code's potential. Don't miss out on this opportunity!
          </p>
        </div>

        {/* Right Column - Form */}
        <div className="space-y-8">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Name Input */}
            <div className="relative">
              <input
                type="text"
                placeholder="Your name here"
                className="w-full bg-transparent border-b border-white/20 py-4 text-white placeholder-white/50 focus:outline-none focus:border-orange-500 transition-colors"
                required
              />
            </div>

            {/* Email Input */}
            <div className="relative">
              <input
                type="email"
                placeholder="Your email here"
                className="w-full bg-transparent border-b border-white/20 py-4 text-white placeholder-white/50 focus:outline-none focus:border-orange-500 transition-colors"
                required
              />
            </div>

            {/* Interest Dropdown */}
            <div className="relative">
              <select
                value={interest}
                onChange={(e) => setInterest(e.target.value)}
                className="w-full bg-transparent border-b border-white/20 py-4 text-white/50 focus:outline-none focus:border-orange-500 transition-colors appearance-none cursor-pointer"
                required
              >
                <option value="" disabled>What are you interested in?</option>
                <option value="mentoring" className="bg-black text-white">Mentoring</option>
                <option value="consulting" className="bg-black text-white">Consulting</option>
                <option value="collaboration" className="bg-black text-white">Collaboration</option>
                <option value="other" className="bg-black text-white">Other</option>
              </select>
              <div className="absolute right-4 top-1/2 -translate-y-1/2 pointer-events-none">
                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 1L6 6L11 1" stroke="white" strokeWidth="2"/>
                </svg>
              </div>
            </div>

            {/* Message Textarea */}
            <div className="relative">
              <textarea
                placeholder="Cut it to a few words"
                rows={4}
                className="w-full bg-transparent border-b border-white/20 py-4 text-white placeholder-white/50 focus:outline-none focus:border-orange-500 transition-colors resize-none"
                required
              />
            </div>

            {/* Privacy Policy Checkbox */}
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                id="privacy"
                className="w-4 h-4 border-white/20 bg-transparent checked:bg-orange-500 appearance-none border rounded-sm cursor-pointer"
                required
              />
              <label htmlFor="privacy" className="text-sm text-white/80">
                By sending my details, I have read and accept the Privacy policy.
              </label>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="group flex items-center gap-2 text-white border border-white/20 rounded-full px-8 py-4 hover:bg-white/5 transition-colors magic-hover"
            >
              Let's talk
              <ArrowUpRight className="w-4 h-4 transition-transform group-hover:translate-x-1 group-hover:-translate-y-1" />
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};