import React from 'react';

interface MarqueeTextProps {
  text: string;
  className?: string;
}

export const MarqueeText: React.FC<MarqueeTextProps> = ({ text, className = '' }) => {
  return (
    <div className="w-full overflow-hidden relative">
      <div className="whitespace-nowrap inline-block animate-marquee">
        {Array(3).fill(text).map((t, i) => (
          <span key={i} className={`inline-block mx-4 ${className}`}>
            {t}
          </span>
        ))}
      </div>
      <div className="absolute top-0 whitespace-nowrap inline-block animate-marquee2" aria-hidden="true">
        {Array(3).fill(text).map((t, i) => (
          <span key={i} className={`inline-block mx-4 ${className}`}>
            {t}
          </span>
        ))}
      </div>
    </div>
  );
};
