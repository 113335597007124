import React from 'react';
import { Section } from './Section';

const servicesData = [
  {
    index: "01",
    title: "Freelance Pack",
    subtitle: "One-time fee",
    description: "Enhance your company's projects with the Freelance Pack, designed for seamless collaboration with freelance developers"
  },
  {
    index: "02",
    title: "Freelance Consultant",
    subtitle: "One-time fee + Monthly fee",
    description: "Take your company's partnerships to the next level with Freelance Consultant. I will work directly with the lead developer on your new Rag/AI integrations."
  },
  {
    index: "03",
    title: "Enterprise Consultant",
    subtitle: "One-time fee + Monthly fee",
    description: "Revolutionize your enterprise's systems with large scale AI intergations."
  }
];

const ServiceRow: React.FC<{
  service: typeof servicesData[0];
  isLast: boolean;
}> = ({ service, isLast }) => (
  <>
    <div className="group py-8 transition-all hover:bg-white/5">
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-1 text-lg text-white/40">
          ({service.index})
        </div>
        <div className="col-span-3">
          <h3 className="text-xl text-white group-hover:text-orange-500 transition-colors">
            {service.title}
          </h3>
        </div>
        <div className="col-span-3 text-white/80">
          {service.subtitle}
        </div>
        <div className="col-span-5 text-white/60">
          {service.description}
        </div>
      </div>
    </div>
    {!isLast && (
      <div className="w-full h-[1px] bg-gradient-to-r from-transparent via-white/10 to-transparent"></div>
    )}
  </>
);

export const ServicesSection: React.FC = () => {
  return (
    <Section id="services" className="bg-black px-8">
      <div className="max-w-7xl mx-auto w-full py-24">
        <div className="mb-16">
          <h2 className="text-8xl font-light text-white mb-4">
            SERVICES
          </h2>
          <p className="text-3xl font-serif italic text-white/60">
            packs
          </p>
        </div>
        
        <div>
          {servicesData.map((service, index) => (
            <ServiceRow 
              key={service.index} 
              service={service} 
              isLast={index === servicesData.length - 1}
            />
          ))}
        </div>
      </div>
    </Section>
  );
};