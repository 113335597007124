import React from 'react';
import { Section } from './Section';

interface Project {
  title: string;
  description: string[];
  tech: string[];
}

const projects: Project[] = [
  {
    title: "Enterprise LLM System",
    description: [
      "Architected distributed AI system handling requests via containerized microservices",
      "Implemented end-to-end ML pipeline with Docker and automated CI/CD testing",
      "Achieved 95% response accuracy through custom evaluation metrics and A/B testing",
      "Built Claude-based RAG system with test-driven development",
      "Integrated multiple APIs (email, SMS, CRM) using RESTful architecture"
    ],
    tech: ["Python", "Docker", "Claude API", "REST APIs", "RAG", "CI/CD", "TDD"]
  },
  {
    title: "Health Analytics Platform",
    description: [
      "Engineered privacy-focused LLM infrastructure with RAG capabilities",
      "Developed automated ML pipeline for health metrics analysis",
      "Implemented evidence-based recommendation engine using RAG"
    ],
    tech: ["Python", "ML", "RAG", "LLM", "Health Analytics"]
  }
];

export const WorkSection: React.FC = () => {
  return (
    <Section id="work" className="bg-black/20">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-6xl font-light text-white mb-16">Selected Work</h2>
        <div className="space-y-24">
          {projects.map((project, index) => (
            <div 
              key={project.title}
              className="group"
            >
              <div className="flex gap-6">
                <div className="text-lg text-white/40 font-mono">
                  ({String(index + 1).padStart(2, '0')})
                </div>
                <div className="flex-1">
                  <h3 className="text-3xl text-white group-hover:text-orange-500 transition-colors mb-8">
                    {project.title}
                  </h3>
                  <ul className="list-disc space-y-4 mb-8 pl-4">
                    {project.description.map((point, i) => (
                      <li 
                        key={i} 
                        className="text-white/70 text-lg leading-relaxed pl-2"
                      >
                        {point}
                      </li>
                    ))}
                  </ul>
                  <div className="flex flex-wrap gap-3">
                    {project.tech.map((tech) => (
                      <span 
                        key={tech} 
                        className="text-white/50 bg-white/5 px-4 py-2 rounded-full text-sm hover:bg-white/10 transition-colors"
                      >
                        {tech}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};